const customerType = document.getElementById("customer_type");

if(customerType){

    const singleMember = document.getElementById("single-member");
    const groupMember = document.getElementById("group-member");
    
    customerType.addEventListener("change", (e) => {
        if (e.currentTarget.checked) {
            groupMember.classList.remove('hidden');
            singleMember.classList.add('hidden');
        } else {
            groupMember.classList.add('hidden');
            singleMember.classList.remove('hidden');
        }

        e.target.value = e.currentTarget.checked;
    })
}

let switcher = document.querySelectorAll('.switch--b .slider');
let codeInput = document.querySelectorAll('.bonus_key');
let switcherValue = document.querySelectorAll('.bonus');
let codeInputValue = document.querySelectorAll('.bonus_key .input-secondary__item');

switcher.forEach((el,i)=> toggleCodeInput(el,i));

$('.member__add.button').click(function(){

    setTimeout(function() {

        switcher = document.querySelectorAll('.switch--b .slider');
        codeInput = document.querySelectorAll('.bonus_key');
        codeInputValue = document.querySelectorAll('.bonus_key .input-secondary__item');
        switcherValue = document.querySelectorAll('.bonus');

        let l = switcher.length-1;

        switcher[l].addEventListener('click',() => {
            if (switcherValue[l].value == 'no') {
                codeInput[l].classList.remove('hidden');
                switcherValue[l].value = 'yes';
                codeInputValue[l].value = ' ';
            } else {
                codeInput[l].classList.add('hidden');
                switcherValue[l].value = 'no';
                codeInputValue[l].value = '';
            }
        })

    }, 100)
})

function toggleCodeInput(el,i) {

    $('.switch--b .slider').unbind('click');

    el.addEventListener('click', function(){

        if (switcherValue[i].value == 'no') {
            console.log(codeInputValue[i].value)
            codeInput[i].classList.remove('hidden');
            switcherValue[i].value = 'yes';
            codeInputValue[i].value = ' ';
        } else {
            codeInput[i].classList.add('hidden');
            switcherValue[i].value = 'no';
            codeInputValue[i].value = '';
        }

    })
};