const { svg } = require("leaflet");

const svgPaths = [...document.getElementsByClassName("ds-path")];

if(svgPaths.length){

    svgPaths.forEach(item => {
        const link = item.dataset.link; 

        item.addEventListener("click", function(e) {
        
            e.preventDefault();
            window.location = link;
        });
    });
    
}

