import LazyLoad from "vanilla-lazyload";
require("./rwd-checker");
require("./form-animation");

function onloadCallback() {
  if ($("#google-captcha").length) {
    grecaptcha.render("google-captcha", {
      sitekey: "6LduCvIaAAAAAH12lfsUOMEDy-uWXF-sANlp4ytr",
    });
  }
}

window.onloadCallback = onloadCallback;

$(function() {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    // aby użyć do elementów dodajemy klasę .lazy
    // zamiast src w img podajemy data-src
    // zamiast background-image podajemy data-bg
  });
  require("./user/user-ajax-forms");
  require("./cookie-bar");
  require("./animations");
  require("./counter-animation");
  require("./dropdown-menu");
  require("./mobile-menu");
  require("./sticky-header");
  require("./pop-up");
  require("./offer-slider");
  require("./input-repeater");
  require("./autocomplete-form");
  require("./svg-link");
  require("./booking-form");

  $('[data-toggle="tooltip"]').tooltip()

  $(".gallery").each(function() {
    $(this)
      .find("a")
      .simpleLightbox();
  });
  if ($(".simplelightbox").length > 0) {
    $(".simplelightbox").simpleLightbox();
  }
});
