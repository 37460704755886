import {
  showMessageAboveForm,
  loader,
  ERROR_MESSAGE,
  scrollToTarget,
} from "../show-message-above-form";

$(function() {
  if ($(".ajaxLoader").length < 1 && $(".ajaxForm").length > 0) {
    const loaderHTML =
      "<div class='loader-wrapper ajaxLoader'><img src='/themes/default/assets/images/loader.svg' ></div>";
    $(".ajaxForm").prepend($(loaderHTML));
  }
});

// change password
$(".button--changepass").click(function(e) {
  e.preventDefault();

  const form = $(".form--changepass");

  const formData = {
    old_password: form.find('input[name="old_password"]').val(),
    password: form.find('input[name="password"]').val(),
    password_confirmation: form
      .find('input[name="password_confirmation"]')
      .val(),
  };

  $.request("onValidate", {
    data: formData,
    success: function(data) {
      const msg = "Hasło zostało zmienione!";
      handleAjaxForm(form, "ChangePassword::onAjax", msg);
    },
    beforeSend: function() {
      loader(form, false);
    },
    complete: function() {
      loader(form, true);
    },
    error: function(data) {
 
      const msg = data.responseJSON.X_OCTOBER_ERROR_MESSAGE;
      showMessageAboveForm(form, msg, true);
    },
  });
});

// change-data
$(".button--changedata").click(function(e) {
  e.preventDefault();

  const form = $(".form--changedata");

  const formData = {
    name: form.find('input[name="name"]').val(),
    last_name: form.find('input[name="last_name"]').val(),
    phone: form.find('input[name="phone"]').val(),
  };

  $.request("onValidate", {
    data: formData,
    success: function(data) {
      const msg = "Twoje dane zostały zmienione.";
      handleAjaxForm(form, "UserPage::onAjax", msg);
    },
    beforeSend: function() {
      loader(form, false);
    },
    complete: function() {
      loader(form, true);
    },
    error: function(data) {

      const msg = data.responseJSON.X_OCTOBER_ERROR_MESSAGE;
      showMessageAboveForm(form, msg, true);
    },
  });
});

// login
$(".button--login").click(function(e) {
  e.preventDefault();
  const form = $(".form--login");

  const formData = {
    email: form.find('input[name="email"]').val(),
    password: form.find('input[name="password"]').val(),
  };

  $.request("onLoginValidate", {
    data: formData,
    success: function(data) {
      const msg = "Zalogowano!";
      handleAjaxForm(form, "Login::onAjax", msg);
    },
    beforeSend: function() {
      loader(form, false);
    },
    complete: function() {
      loader(form, true);
    },
    error: function(data) {
      const msg = data.responseJSON.X_OCTOBER_ERROR_MESSAGE;
      showMessageAboveForm(form, msg, true);
    },
  });
});

// registration
$(".button--register").click(function(e) {
  e.preventDefault();
  const form = $(".form--register");
  const formData = {
    name: form.find('input[name="name"]').val(),
    last_name: form.find('input[name="last_name"]').val(),
    email: form.find('input[name="email"]').val(),
    phone: form.find('input[name="phone"]').val(),
    password: form.find('input[name="password"]').val(),
    password_confirmation: form
      .find('input[name="password_confirmation"]')
      .val(),
    accept_terms: form.find('input[name="accept_terms"]:checked').val(),
    rodo: form.find('input[name="rodo"]:checked').val(),
  };

  // if (grecaptcha.getResponse() !== "") {
    $.request("onRegisterValidate", {
      data: formData,
      success: function(data) {
        const msg = "Rejestracja przebiegła pomyślnie!";
        handleAjaxForm(form, "Registration::onAjax", msg);
      },
      beforeSend: function() {
        loader(form, false);
      },
      complete: function() {
        loader(form, true);
      },
      error: function(data) {
        const msg = data.responseJSON.X_OCTOBER_ERROR_MESSAGE;
        showMessageAboveForm(form, msg, true);
      },
    });
  // } else {
  //   const msg = "Potwierdź, że nie jesteś robotem";
  //   showMessageAboveForm(form, msg, true);
  // }
});

// restore password
$(".button--restore").click(function(e) {
  e.preventDefault();
  handleAjaxForm($(".form--restore"), "RestorePassword::onAjax");
});

// reset password
$(".button--resetpass").click(function(e) {
  e.preventDefault();

  const form = $(".form--resetpass");
  const formData = {
    password: form.find('input[name="password"]').val(),
    password_confirmation: form
      .find('input[name="password_confirmation"]')
      .val(),
  };

  $.request("onValidate", {
    data: formData,
    success: function(data) {
      const msg = "Hasło zostało zmienione!";
      handleAjaxForm(form, "ResetPassword::onAjax", msg);
    },
    beforeSend: function() {
      loader(form, false);
    },
    complete: function() {
      loader(form, true);
    },
    error: function(data) {
      const msg = data.responseJSON.X_OCTOBER_ERROR_MESSAGE;
      showMessageAboveForm(form, msg, true);
    },
  });
});

function handleAjaxForm(form, ajaxRequest, successMsg = false) {
  form.request(ajaxRequest, {
    success: function(data) {
      const message = {
        content: data.message || successMsg,
        status: data.hasOwnProperty("status") ? !data.status : false,
      };

      if (message.content) {
        showMessageAboveForm(form, message.content, message.status);
      }

      if (data.X_OCTOBER_REDIRECT) {
        window.location = data.X_OCTOBER_REDIRECT;
      }
    },
    beforeSend: function() {
      loader(form, false);
    },
    complete: function() {
      loader(form, true);
    },
    error: function(data) {
      showMessageAboveForm(form, ERROR_MESSAGE, true);
    },
  });
}
